<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="'images/swift.svg'" />
		</router-link>
		<!--<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>-->

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
        {{displayName}} - Fulfillment
				<button class="p-link layout-topbar-button" @click="logout">

					<i class="pi pi-user"></i>
					<span>Profile</span>

				</button>
			</li>
		</ul>
	</div>
</template>

<script>
import {auth } from "./logic/firebase";
var user;
var displayName = '';
var userDataJSON = {branchName: ''};

export default {
  data(){
    return{
      displayName: displayName,
      userDataJSON: userDataJSON
    }
  },
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
      logout(){
          auth.signOut()
      },
    },
	computed: {
		darkTheme() {
			return this.$appState.theme.startsWith('saga');
		}
	},
  mounted() {
    if(auth.currentUser){
      this.user = auth.currentUser;
      this.displayName = this.user.displayName;
    }

  },
}
</script>