<template>
	<div class="layout-footer">
		<span class="font-medium ml-2">Copyright © 2022 Swift Laundry - Todos los derechos reservados</span>
	</div>
  <div style="text-align: center; margin-top: 1em">
    <span class="font-medium ml-2">Swift Fulfillment v1.0.0r1</span>
  </div>
</template>

<script>
	export default {
		name: "AppFooter",
		computed: {
			darkTheme() {
				return this.$appState.theme.startsWith('saga');
			}
		}
	}
</script>

<style scoped>

</style>