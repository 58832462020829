import { firebase } from '@firebase/app'
import '@firebase/auth'
import '@firebase/firestore'
import '@firebase/storage'
import '@firebase/functions'

// firebase init - add your own config here
const firebaseConfig = {
    apiKey: "AIzaSyD3nyIzWVa_mmaM3AegWvg2_Dewr-Ly8wc",
    authDomain: "misfinanzas0.firebaseapp.com",
    databaseURL: "https://misfinanzas0.firebaseio.com",
    projectId: "misfinanzas0",
    storageBucket: "misfinanzas0.appspot.com",
    messagingSenderId: "793253885948",
    appId: "1:793253885948:web:5b57d110f0651e0b5b2479",
    measurementId: "G-W2V0JR1FSM"
};

firebase.initializeApp(firebaseConfig)


// utils
const db = firebase.firestore()
const functions = firebase.functions()
const auth = firebase.auth();

// collection references
const pickup = db.collection('pickups');
const stateHistory = db.collection('stateHistory');
const pickupSequence = db.collection('pickupSequences');
const sendNewStateNotificationByUID = functions.httpsCallable('sendNewStateNotificationUID')

// export utils/refs
export {
    db,
    auth,
    pickup,
    stateHistory,
    sendNewStateNotificationByUID,
    pickupSequence
}
