import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/Dashboard',
        name: 'Tablero general',
        component: () => import('./components/Dashboard.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/OrderUpdate',
        name: 'Actualizar órdenes',
        component: () => import('./components/OrderUpdate.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/OrderTracking',
        name: 'Rastrear órdenes',
        component: () => import('./components/OrderTracking'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/PrintGuidesSelector',
        name: 'Imprimir guías',
        component: () => import('./components/PrintGuidesSelector'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/PrintGuides',
        name: 'Imprimir guías ',
        component: () => import('./components/PrintGuides'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./components/Login.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
